import { AvatarBase, AvatarImage, AvatarFallback } from "../avatar-base";
import { getInitials } from "~/utils/getInitials";
import { AvatarProps } from "./avatar.types";
import { avatarStyles } from "./avatar.styles";
import { cn } from "~/utils/cn";

export const Avatar: React.FC<AvatarProps> = ({
  id,
  fullName,
  src,
  atEnd = false,
  displayFullName = false,
  backgroundColor,
  size,
  className,
  verticalAlignment,
}) => {
  const { avatar, avatarFallback, container, text } = avatarStyles({
    size,
    verticalAlignment,
  });

  return (
    <div className={container()} data-testid="avatar-group-item">
      <AvatarBase className={cn(avatar(), className)}>
        {!atEnd && (
          <AvatarImage
            id={String(id)}
            src={src}
            aria-label={fullName}
            alt={fullName}
          />
        )}
        <AvatarFallback
          className={avatarFallback()}
          {...(backgroundColor ? { style: { backgroundColor } } : {})}
        >
          {atEnd ? fullName : getInitials(fullName)}
        </AvatarFallback>
      </AvatarBase>
      {displayFullName && <span className={text()}>{fullName}</span>}
    </div>
  );
};
