import { iconClassName } from "~/scalis-components/core/select/select.styles";
import { SelectVariant } from "~/scalis-components/core/select/select.types";

type CurrentIconProps = {
  variant: SelectVariant;
  defaultIcon: JSX.Element;
  isDropListOpen?: boolean;
  name: string;
  setValue: (name: string, value: string | string[]) => void;
  value?: string | string[];
};

export const CurrentIcon: React.FC<CurrentIconProps> = ({
  variant,
  defaultIcon,
  isDropListOpen = false,
  name,
  setValue,
  value,
}) => {
  switch (variant) {
    case SelectVariant.inline:
      return <i className={iconClassName({ variant })} />;

    case SelectVariant.filter:
      return (
        <>
          <i
            data-is-open={isDropListOpen}
            className={iconClassName({ variant })}
          />

          {(value?.length ?? -1) > 0 && (
            <i
              className="fa-light fa-xmark z-50 text-sm text-icon-neutral-20"
              role="button"
              onClick={() => setValue(name, [])}
            />
          )}
        </>
      );

    default:
      return defaultIcon;
  }
};
