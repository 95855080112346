import winston, { format } from "winston";
import DatadogWinston from "datadog-winston";

global.setImmediate =
  global.setImmediate ||
  ((fn: (...args: unknown[]) => void, ...args: unknown[]) =>
    global.setTimeout(fn, 0, ...args));

const errorStackFormat = format(info => {
  if (info instanceof Error) {
    return Object.assign({}, info, {
      message: `${info.message} ${info.stack}`.replace(/\n/g, " "),
      stack: info.stack,
    });
  }
  if (info.error && Object.keys(info.error).length > 0) {
    return Object.assign({}, info, {
      message: `${info.error.message} ${info.error.stack}`.replace(/\n/g, " "),
      stack: info.error.stack,
    });
  }
  return info;
});

const transporters: any = [
  new winston.transports.Console({
    format: format.combine(
      format.timestamp(),
      format.printf(({ timestamp, level, message, ...rest }) => {
        return `${timestamp} ${level}: ${message} ${JSON.stringify(rest)}`;
      }),
    ),
  }),
];

if (process.env.DATADOG_API_KEY) {
  const datadogTransport = new DatadogWinston({
    apiKey: process.env.DATADOG_API_KEY,
    hostname: process.env.APP_URL ?? process.env.NEXT_PUBLIC_APP_URL,
    service: `scalis-${process.env.NODE_ENV}`,
    ddsource: "scalis-io-nextjs",
    ddtags: `service:scalis-${process.env.NODE_ENV}, env:${process.env.NODE_ENV}, `,
  });
  transporters.push(datadogTransport);
}

const logger = winston.createLogger({
  format: format.combine(
    format.timestamp(),
    format.json({ space: 0 }),
    errorStackFormat(),
  ),
  defaultMeta: { service: "scalis" },
  transports: transporters,
});

export default logger;
