import * as PopoverPrimitive from "@radix-ui/react-popover";
import { forwardRef, useState } from "react";
import { cn } from "~/utils/cn";
import { SelectProps, SelectVariant } from "../../select.types";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/scalis-components/core/command";
import { getSelected } from "./drop-list.utils";
import { EmptyList, PopoverContent } from "./components";
import { Tag } from "~/scalis-components/core/tag";
import { Button, ButtonKind } from "~/scalis-components/core/button";
import { Divider } from "~/scalis-components/core/divider";
import { Popover, PopoverTrigger } from "~/scalis-components/core/popover";
import { ScrollArea } from "~/scalis-components/core/scroll-area";
import { OptionIcon } from "./components/option-icon";
import { SelectedContent } from "./components/selected-content/selected-content";
import {
  buttonClassName,
  selectedContentContainerClassName,
} from "./drop-list.styles";

export const DropList = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      emptyTerm,
      multi,
      collisionPadding,
      name = "",
      options,
      placeholder = null,
      searchPlaceholder,
      setValue,
      value,
      searchByLabel = true,
      disabled,
      className,
      icon,
      onBlur,
      onChange,
      variant = SelectVariant.default,
      dataType,
      startAdornment,
      scrollHeight,
      emptyIcon,
      setIsDropListOpen,
      ...props
    },
    ref,
  ) => {
    const [open, setOpen] = useState<boolean>(props?.open || false);

    const selectedOptions = getSelected(value, options);

    const selectedValue = multi
      ? selectedOptions?.map(option => {
          return (
            <Tag
              small
              key={option?.value}
              text={option?.label}
              closeButton={{
                hasCloseButton: true,
                onClose: () => {
                  if (Array.isArray(value)) {
                    const newValue = value?.filter?.(
                      (v: any) => v !== option?.value,
                    );
                    setValue(name, newValue);
                  }
                },
              }}
            />
          );
        })
      : selectedOptions?.[0]?.label;
    const hasSelectedValue = selectedOptions.length > 0;

    const handleSelect = (newValue: string) => {
      if (multi) {
        const values = value;
        if (Array.isArray(values) && values?.includes(newValue)) {
          return setValue(
            name,
            values?.filter?.((v: any) => v !== newValue),
            { shouldValidate: true },
          );
        }
        return setValue(name, [...(values || []), newValue], {
          shouldValidate: true,
        });
      }
      setValue(name, newValue, { shouldValidate: true });
      return handleOpenChange(false);
    };

    const handleOpenChange = (state: boolean) => {
      if (!state && onBlur) {
        onBlur();
      }
      setOpen(state);
      setIsDropListOpen?.(state);
    };

    return (
      <Popover open={open} onOpenChange={handleOpenChange}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            kind={ButtonKind.combobox}
            role="combobox"
            aria-label={props["aria-label"]}
            disabled={disabled}
            data-is-open={open}
            className={cn(buttonClassName({ variant }), className)}
          >
            <div
              className={selectedContentContainerClassName({ variant })}
              aria-label="Selected value"
            >
              <SelectedContent
                variant={variant}
                placeholder={placeholder}
                icon={icon}
                selectedValue={selectedValue}
                emptyTerm={emptyTerm}
                hasSelectedValue={hasSelectedValue}
              />
            </div>
            {variant !== SelectVariant.inline && icon}
          </Button>
        </PopoverTrigger>
        <PopoverPrimitive.Content
          className={cn(
            "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0",
            "data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
            "data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2",
            "data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50",
            "text-popover-foreground outline-none",
            "text-sm font-normal leading-6 text-neutral-primary",
            "border border-neutral-00 shadow-drop-list",
            "rounded-xl bg-neutral-00",
            {
              "w-popover-trigger": variant !== SelectVariant.filter,
            },
          )}
          sideOffset={0}
          collisionPadding={collisionPadding}
          align={variant === SelectVariant.filter ? "start" : undefined}
        >
          <PopoverContent
            searchPlaceholder={searchPlaceholder}
            emptyTerm={emptyTerm}
            scrollHeight={scrollHeight}
            options={options}
            multi={multi}
            value={value}
            searchByLabel={searchByLabel}
            onChange={onChange}
            handleSelect={handleSelect}
            startAdornment={startAdornment}
          />
        </PopoverPrimitive.Content>
      </Popover>
    );
  },
);
