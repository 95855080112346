import React from "react";
import {
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerGroup,
  DrawerHeader,
  DrawerTitle,
} from "./drawer-group";
import { Button, ButtonKind, ButtonStandaloneKind } from "../button";
import { DrawerProps } from "./drawer.types";
import { cn } from "~/utils/cn";
import { DEFAULT_PROPS_CANCEL_BUTTON } from "./drawer.constants";
import { Inter } from "next/font/google";

const inter = Inter({ subsets: ["latin"] });

export const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onOpenChange,
  children,
  actions,
  title,
  successButtonProps,
  standaloneButtonProps,
  secondaryButtonProps,
  fullWidth,
  cancelButtonProps = DEFAULT_PROPS_CANCEL_BUTTON,
  ariaDescribedBy = "",
  handlerOnHeaderCloseButtonClick,
  handlerOnOverlayClick,
  contentClassName,
  footerContent,
  hideFooterContent = false,
  endAlignedFooterActions,
}) => {
  const {
    label,
    hidden: hideSuccessButton,
    ...successButton
  } = successButtonProps ?? {};
  const { hidden: hideSecondaryButton } = secondaryButtonProps ?? {};
  const { hidden: hideCancelButton } = cancelButtonProps ?? {};

  const hasSuccessButton = !hideSuccessButton && !!successButtonProps;
  const hasSecondaryButton = !hideSecondaryButton && !!secondaryButtonProps;
  const hasCancelButton = !hideCancelButton && !!cancelButtonProps;

  const buttonsToShow = [
    hasSuccessButton,
    hasSecondaryButton,
    hasCancelButton,
  ].filter(Boolean).length;

  return (
    <DrawerGroup open={isOpen} onOpenChange={onOpenChange}>
      <DrawerContent
        asChild
        className={cn(fullWidth && "w-full", inter.className)}
        aria-describedby={ariaDescribedBy ?? "Drawer Content"}
        handlerOnOverlayClick={handlerOnOverlayClick}
      >
        <div data-vaul-no-drag>
          <DrawerHeader
            actions={actions}
            handlerOnCloseButtonClick={handlerOnHeaderCloseButtonClick}
          >
            {/* TODO: Adjust this properly so we use the DrawerTitle instead of H1 */}
            {/* Also we must evaluate headings hierarchy all over the app. */}
            <DrawerTitle role="screen-reader-title" className="sr-only">
              {title}
            </DrawerTitle>
            <h1 className="text-base font-medium tracking-tighter text-neutral-primary">
              {title}
            </h1>
          </DrawerHeader>

          <div className={cn("h-screen overflow-y-auto p-4", contentClassName)}>
            {children}
          </div>

          <DrawerFooter className="flex justify-between">
            {footerContent ? (
              <div className="flex w-full">{footerContent}</div>
            ) : (
              <>
                {standaloneButtonProps != null && (
                  <div>
                    <Button
                      {...standaloneButtonProps}
                      isStandalone={true}
                      kind={ButtonStandaloneKind.brand}
                    >
                      {standaloneButtonProps.label}
                    </Button>
                  </div>
                )}

                <div
                  className={cn("grid w-full grid-cols-2 gap-2", {
                    "grid-cols-3": buttonsToShow === 3,
                    "flex flex-row items-end": standaloneButtonProps != null,
                  })}
                >
                  <DrawerClose asChild>
                    {hasCancelButton && (
                      <Button
                        {...cancelButtonProps}
                        isStandalone={false}
                        kind={ButtonKind.secondary}
                      >
                        {cancelButtonProps.label}
                      </Button>
                    )}
                  </DrawerClose>
                  {hasSecondaryButton && (
                    <Button
                      {...secondaryButtonProps}
                      isStandalone={false}
                      kind={ButtonKind.secondary}
                    >
                      {secondaryButtonProps.label}
                    </Button>
                  )}
                  {hasSuccessButton && (
                    <Button {...successButton}>{label}</Button>
                  )}
                </div>
              </>
            )}
          </DrawerFooter>
        </div>
      </DrawerContent>
    </DrawerGroup>
  );
};
