export type AvatarProps = {
  id?: string | number;
  src?: string;
  fullName?: string;
  atEnd?: boolean;
  displayFullName?: boolean;
  backgroundColor?: string;
  size?: AvatarVariantSizeEnum;
  className?: string;
  verticalAlignment?: AvatarItemAlignmentEnum;
};

export enum AvatarVariantSizeEnum {
  Small = "small",
  Default = "default",
  Medium = "medium",
  Large = "large",
  ExtraLarge = "extraLarge",
  XXL = "xxl",
}

export enum AvatarItemAlignmentEnum {
  Center = "center",
  Base = "base",
}
