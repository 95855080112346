import React from "react";

import { Dialog, DialogContent, DialogTitle, DialogTrigger } from "../dialog";
import { Button, ButtonKind, ButtonWidth } from "../../button";
import {
  ConfirmationDialogKind,
  ConfirmationDialogProps,
} from "./confirmation-dialog.types";
import { iconStyles } from "./confirmation-dialog.styles";
import { DialogClose, DialogDescription } from "@radix-ui/react-dialog";
import { cn } from "~/utils/cn";

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
  onOpenChange,
  icon,
  hasCancelButton,
  disabled,
  description,
  loading,
  kind = ConfirmationDialogKind.information,
  className,
  ...props
}) => {
  if (!isOpen) {
    return null;
  }
  const labelConfirm = props.labelConfirm ?? "Confirm";
  const labelCancel = props.labelCancel ?? "Cancel";

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent
        className={cn(
          "flex w-[375px] flex-col gap-0 rounded-xl p-6",
          className,
        )}
      >
        <DialogDescription className="hidden">
          {description ?? title}
        </DialogDescription>

        <div className="flex flex-col gap-2">
          <DialogTitle className="flex items-center gap-2 text-base font-medium text-neutral-primary">
            <i className={iconStyles({ kind })} />
            {title}
          </DialogTitle>
          <p className="text-sm/6 font-normal text-neutral-primary">
            {message}
          </p>
        </div>
        <div className="mt-6 flex w-full gap-2">
          <DialogTrigger asChild>
            <Button
              width={ButtonWidth.full}
              kind={ButtonKind.secondary}
              onClick={onCancel}
              aria-label={labelCancel}
              disabled={loading}
            >
              {labelCancel}
            </Button>
          </DialogTrigger>
          <Button
            kind={
              kind === ConfirmationDialogKind.information
                ? ButtonKind.primary
                : ButtonKind.critical
            }
            width={ButtonWidth.full}
            disabled={disabled ?? loading}
            loading={loading}
            onClick={onConfirm}
            aria-label={labelConfirm}
          >
            {labelConfirm}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
